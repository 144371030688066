import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignUpStep1Component } from '@modules/sign-up/components/sign-up-step1/sign-up-step1.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { VerifyPhoneDialogComponent } from '@modules/events/dialogs/verify-phone-dialog/verify-phone-dialog.component';
import { CredentialsFormButtonToggleComponent } from '@modules/shared/components/credentials-form-button-toggle/credentials-form-button-toggle.component';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TelInputComponent } from '@modules/shared/components/tel-input/tel-input.component';
import { AuthService } from '@services/auth/auth.service';
import { AppRoutes } from '../../../../routes';
import { Router } from '@angular/router';
import { LoginRequest } from '@models/users/dto/login.request';
import { SsoButtonsComponent } from '@modules/auth/components/sso-buttons/sso-buttons.component';
import { HCaptchaInfoComponent } from '@modules/shared/components/h-captcha-info/h-captcha-info.component';
import { VerifyEmailStepComponent } from '@modules/sign-up/components/verify-email-step/verify-email-step.component';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-login-step2',
  standalone: true,
  imports: [
    CommonModule,
    SignUpStep1Component,
    TranslateModule,
    VerifyPhoneDialogComponent,
    CredentialsFormButtonToggleComponent,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    TelInputComponent,
    SsoButtonsComponent,
    HCaptchaInfoComponent,
    VerifyEmailStepComponent,
    MatProgressSpinner,
  ],
  templateUrl: './login-step2.component.html',
  styleUrl: './login-step2.component.scss',
})
export class LoginStep2Component implements OnChanges {
  @Input() isAuthDialog = false;
  @Input() email?: string;
  @Input() step: number = 1;

  form: FormGroup;

  showPassword = false;
  loginIsInProgress = false;

  userId?: number;
  userEmail?: string;
  userPassword?: string;

  @Output() success = new EventEmitter<void>();

  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
    private router: Router,
  ) {
    this.form = new FormGroup({
      email: new FormControl(this.email || '', [
        Validators.required,
        Validators.email,
      ]),
      password: new FormControl('', [Validators.required]),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form.controls['email'].setValue(this.email);
  }

  goToForgotPassword(): void {
    if (this.isAuthDialog) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['/', AppRoutes.Root.forgotPassword]),
      );
      window.open(url, '_blank');
    } else {
      this.router.navigate(['/', AppRoutes.Root.forgotPassword]);
    }
  }

  login() {
    this.loginIsInProgress = true;

    const values = this.form.value;
    const loginReq: LoginRequest = {
      email: values.email,
      password: values.password,
    };

    const campaignUri = localStorage.getItem('last-campaign-before-login');
    const eventUri = localStorage.getItem('last-campaign-event-before-login');

    this.authService.userOrAccountWithCredentialsExists(loginReq).subscribe({
      next: (exists) => {
        if (exists['exists']) {
          this.finalizeLogin(loginReq, campaignUri, eventUri);
        } else {
          this.loginIsInProgress = false;
          this.step = 2;
          this.userId = exists['userId'];
          this.userEmail = values.email;
          this.userPassword = values.password;
        }
      },
      error: (error) => {
        this.loginIsInProgress = false;
        if (error.message === 'wrong password') {
          this.form.controls['password'].setErrors({
            custom: this.translateService.instant(
              'APP.AUTH.ERRORS.WRONG_PASSWORD',
            ),
          });
        }
      },
    });
  }

  afterVerificationLogin() {
    const loginReq: LoginRequest = {
      email: this.userEmail,
      password: this.userPassword,
    };

    const campaignUri = localStorage.getItem('last-campaign-before-login');
    const eventUri = localStorage.getItem('last-campaign-event-before-login');

    this.finalizeLogin(loginReq, campaignUri, eventUri);
  }

  finalizeLogin(
    loginReq: LoginRequest,
    campaignUri: string | null,
    eventUri: string | null,
  ) {
    this.authService.login(
      loginReq,
      !this.isAuthDialog && !(campaignUri && eventUri),
      () => {
        if (this.isAuthDialog) {
          this.success.emit();
        } else {
          this.loginIsInProgress = false;

          if (
            campaignUri &&
            campaignUri !== '' &&
            eventUri &&
            eventUri !== ''
          ) {
            this.router.navigate(['/', 'c', campaignUri, 'voting', eventUri], {
              replaceUrl: true,
            });

            localStorage.removeItem('last-campaign-before-login');
            localStorage.removeItem('last-campaign-event-before-login');
          }
        }
      },
      (denied: any) => {
        this.loginIsInProgress = false;
        if (denied && denied.error) {
          if (denied.error === 'could not load user: record not found') {
            this.form.controls['email'].setErrors({
              custom: this.translateService.instant(
                'APP.AUTH.ERRORS.USER_NOT_FOUND',
              ),
            });
          }
        } else if (denied && denied.message) {
          if (denied.message === 'account_blocked') {
            this.form.controls['password'].setErrors({
              custom: this.translateService.instant(
                'APP.AUTH.ERRORS.ACCOUNT_BLOCKED',
              ),
            });
          } else if (denied.message === 'unauthorized') {
            this.form.controls['password'].setErrors({
              custom: this.translateService.instant(
                'APP.AUTH.ERRORS.WRONG_PASSWORD',
              ),
            });
          }
        } else {
          this.form.controls['password'].setErrors({
            custom: this.translateService.instant(
              'APP.AUTH.ERRORS.INVALID_EMAIL_OR_PASSWORD',
            ),
          });
        }
      },
    );
  }
}
