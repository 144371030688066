<div class="login-step-2 form-380" *ngIf="step === 1">
  <h1>{{ "APP.AUTH.ENTER_PASSWORD" | translate }}</h1>
  <div class="subheader subheader-sm">
    {{ "APP.AUTH.ENTER_PASSWORD_SUBHEADER" | translate }}
  </div>

  <div class="form-380">
    <form class="form" [formGroup]="form" (keyup.enter)="login()">
      <mat-form-field
        class="w-100 email-input"
        [class.has-value]="
          form.controls['email'].value && form.controls['email'].value !== ''
        "
        appearance="outline"
      >
        <mat-label>{{ "APP.AUTH.EMAIL_ADDRESS" | translate }}</mat-label>
        <input type="email" matInput formControlName="email" placeholder="" autocomplete="username"/>
        @if (form.controls["email"].hasError("custom")) {
          <mat-error>
            {{ form.controls["email"].getError("custom") }}
          </mat-error>
        } @else if (
          form.controls["email"].hasError("email") &&
          !form.controls["email"].hasError("required")
        ) {
          <mat-error>{{
            "APP.AUTH.ERRORS.EMAIL_INVALID" | translate
          }}</mat-error>
        } @else if (form.controls["email"].hasError("required")) {
          <mat-error>
            {{ "APP.AUTH.ERRORS.EMAIL_REQUIRED" | translate }}
          </mat-error>
        }
      </mat-form-field>

      <mat-form-field
        class="w-100"
        [class.has-value]="
          form.controls['password'].value &&
          form.controls['password'].value !== ''
        "
        appearance="outline"
      >
        <mat-label>{{ "APP.AUTH.PASSWORD" | translate }}</mat-label>
        <input
          matInput
          formControlName="password"
          placeholder=""
          type="{{ showPassword ? 'text' : 'password' }}"
          autocomplete="current-password"
        />
        <i
          matSuffix
          (click)="showPassword = !showPassword"
          class="fal show-password-suffix"
          [class.fa-eye]="!showPassword"
          [class.fa-eye-slash]="showPassword"
        ></i>
        @if (form.controls["password"].hasError("custom")) {
          <mat-error>
            {{ form.controls["password"].getError("custom") }}
          </mat-error>
        } @else if (form.controls["password"].hasError("required")) {
          <mat-error>{{
            "APP.AUTH.ERRORS.PASSWORD_REQUIRED" | translate
          }}</mat-error>
        }
      </mat-form-field>

      <button
        type="button"
        class="btn btn-primary-party-page"
        (click)="login()"
        [disabled]="loginIsInProgress"
        [class.spinner]="loginIsInProgress"
      >
        {{ "APP.AUTH.LOGIN" | translate }}
      </button>
    </form>

    <div class="auth-fallback-container">
      <span class="text-grey">{{
        "APP.AUTH.FORGOT_PASSWORD" | translate
      }}</span>
      <span class="text-link" (click)="goToForgotPassword()">
        {{ "APP.AUTH.RESET_PASSWORD" | translate }}
      </span>
    </div>

    <!-- <div class="hr-text"><span>{{ "APP.OR" | translate }}</span></div>

    <app-sso-buttons></app-sso-buttons>

    <app-h-captcha-info></app-h-captcha-info> -->
  </div>
</div>

<div *ngIf="step === 2">
  <app-verify-email-step
    [email]="userEmail"
    [userId]="userId"
    (verifyEmailSuccess)="afterVerificationLogin()"
  >
  </app-verify-email-step>
</div>
